import React,{ useState } from 'react';
import {Box,Stack,Link, Divider} from '@mui/material'
import { useSearchParams,useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';




function Header() {

    const [searchParams] = useSearchParams();
    const pathname = useLocation().pathname;

    let queryString = searchParams.toString()
    // if(queryString?.length > 0){
    //     queryString = `?${queryString}`;
    // }
    console.log(pathname)

    const style_menu={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939"
    }

    const active_menu={
        //color:"#4771DF",
        "&::before":{
            width:"100%"
        }
    }

    const style_menu2={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939",
        width:"100%",
        padding:"20px 20px",
        
        
    }


    const [isOpen,setIsOpen] = useState(false);

    return (
        <Box component="header" position="fixed" left={0} right={0} top={0} backgroundColor="rgba(255, 255, 255, 0.9)" zIndex={999} boxShadow="1px 1px 2px rgba(0,0,0,0.3)">
            <Stack className='section-content' flexDirection="row" margin="0 auto" justifyContent="space-between" alignItems="center" gap={1} p={1}>
                <Link sx={style_menu} href={"/"+queryString}>
                    {/* <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Logo.png"} alt="logo" width={170} mr={5}></Box> */}
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/logo192.png"} alt="logo" width={{xs:40,md:50}} borderRadius={5}></Box>
                        <Box fontSize={20}>特定非営利活動法人ありんこ</Box>
                    </Stack>
                </Link>
               

                <Stack component="nav" display={{xs:"none",md:"flex"}} flex={1} flexDirection="row" gap={3} justifyContent="end" mr={5}>
                    <Link sx={style_menu} href={"/"}>
                        <Box component="span" sx={pathname==="/" ? active_menu:{}}>TOP</Box>
                    </Link>
                    <Link sx={style_menu} href={"/#service"}>
                        <Box component="span" sx={queryString==="#service" ? active_menu:{}}>事業・活動</Box>
                    </Link>
                    <Link sx={style_menu} href={"/#management"}>
                        <Box component="span" sx={queryString==="#management" ? active_menu:{}}>運営</Box>
                    </Link>
                    <Link sx={style_menu} href={"/#about-us"}>
                        <Box component="span" sx={queryString==="#about-us" ? active_menu:{}}>法人情報</Box>
                    </Link>
                    <Link sx={style_menu} href={"/#recruit"}>
                        <Box component="span" sx={queryString==="#recruit" ? active_menu:{}}>採用情報</Box>
                    </Link>
                    <Link sx={style_menu} href={"/#report"}>
                        <Box component="span" sx={queryString==="#report" ? active_menu:{}}>事業報告</Box>
                    </Link>
                </Stack>

               

                <Box display={{xs:"flex",md:"none"}} onClick={()=>{setIsOpen(!isOpen)}}>
                    {isOpen===false ? <MenuIcon/>:<CloseIcon/>}
                </Box>
            </Stack>
            {isOpen &&
                <Stack flexDirection="column" pt="70px" position="fixed" zIndex={-1} top={0} bottom={0} left={0} right={0} backgroundColor="white" alignItems="center" >
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/" ? active_menu:{}}>TOP</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/#service"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/#service" ? active_menu:{}}>事業・活動</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/#management"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/#management" ? active_menu:{}}>運営</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/#about-us"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/#about-us" ? active_menu:{}}>法人情報</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    {/* <Divider flexItem/>
                    <Link sx={style_menu2} href={"/#recruit"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/#recruit" ? active_menu:{}}>採用情報</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link> */}
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/#report"} onClick={()=>{setIsOpen(!isOpen)}}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/#report" ? active_menu:{}}>事業報告</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                </Stack>
            }
            
        </Box>
        
    );
}

export default Header;