import React from 'react';
import {Chip, Stack, Box, Grid, Card, CardContent, CardActionArea, CardMedia, AccordionDetails, AccordionSummary, Accordion, Link, Typography, CardActions } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LaunchIcon from '@mui/icons-material/Launch';
import { Navigate } from 'react-router-dom';

function Top() {


  const sectionContainerStyle={
    m:{xs:"0 20px", md:"0 auto"},
    maxWidth:1200,
  }
  
  
  const titleStyle={
    component:"span",
    fontSize:{xs:30,md:45},
    fontWeight:"bold",
    ml:1,
    mr:1,
    color:"#e91e63",
    
  }

  const h1Style={
    component:"h1",
    fontSize:{xs:24,md:30},
    fontWeight:"bold",
  }

  const h2Style={
    component:"h2",
    fontSize:{xs:18,md:20},
    fontWeight:"bold",
  }
 
  return (
   <Box>
        <Box position="relative">
        <Box zIndex={-1} width="100vw" height="100vh" position="fixed" sx={{backgroundImage:"url('/static/media/firstview.jpg')",backgroundRepeat: "no-repeat",backgroundSize:"cover"}}></Box>
        </Box>

        <Header/>

        <Box id="top" component="section" backgroundColor="#0000001f" pt={15} pb={10}>
          <Box {...sectionContainerStyle} color="white">
            
              <Grid container>
                <Grid item xs={12} md={8} sx={{textShadow:"gray 1px 2px;"}}>
                  <Typography variant="h1" {...h1Style}>児童の<Box {...titleStyle}>健やかな成長</Box>と</Typography>
                  <Typography variant="h1" {...h1Style} ml={{xs:2,md:20}}><Box {...titleStyle}>豊かな地域社会</Box>を目指す</Typography>
                  
                  <Typography variant="h1" {...h1Style} mt={7} fontSize={{xs:45,md:50}}>特定非営利活動法人ありんこ</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box id="service" component="section" bgcolor="#ffffffcc" pt={5} pb={5}>
          <Box {...sectionContainerStyle} >
            <Typography variant="h2" {...h1Style} textAlign="center">事業・活動</Typography>
            <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={1} ></Box>

            <Grid container spacing={3} mt={1}>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='body1'>放課後児童クラブの開設及び運営</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='body1' >地域の子どもたちの健やかな成長を図り、豊かな地域生活づくり</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='body1' >共働き家庭・ひとり親家庭の就労環境の維持・向上のサポート、子育て支援</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='body1' >子どもを対象とした交流会・イベントの企画及び運営</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='body1'>地域における子育て親子の交流促進</Typography>
                  </CardContent>
                </Card>
              </Grid>


            </Grid>
               
          </Box>
        </Box>

        <Box id="management" component="section" bgcolor="#a8deffd1" pt={5} pb={5}>
          <Box {...sectionContainerStyle} >
            <Typography variant="h2" {...h1Style} textAlign="center">運営</Typography>
            <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={1} ></Box>

            <Grid container spacing={3} mt={1}>

              <Grid item xs={12} md={6}>
                <Card>
                <CardActionArea href="http://totsukagakudou.club/" target="_blank" rel="noopener noreferrer">
                    <CardContent>
                      <Stack flexDirection="row">
                        <Typography variant="h3" flex={1} {...h2Style}>戸塚学童保育</Typography>
                        <LaunchIcon/>
                      </Stack>
                    </CardContent>
                    <CardMedia component="img" height="150" image="/static/media/management1.png" alt="戸塚学童保育"/>
                    {/* <CardActions >
                      <Button variant='outlined'>リンク先を確認する</Button>
                    </CardActions> */}
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardActionArea href="http://totsukagakudou.club/" target="_blank" rel="noopener noreferrer">
                    <CardContent>
                      <Stack flexDirection="row">
                        <Typography variant="h3" flex={1} {...h2Style}>第二戸塚学童保育</Typography>
                        <LaunchIcon/>
                      </Stack>
                    </CardContent>
                    <CardMedia component="img" height="150" image="/static/media/management2.png" alt="第二戸塚学童保育"/>
                    {/* <CardActions >
                      <Button variant='outlined'>リンク先を確認する</Button>
                    </CardActions> */}
                  </CardActionArea>
                </Card>
              </Grid>


            </Grid>
               
          </Box>
        </Box>

        <Box id="about-us" component="section" bgcolor="#ffffffcc" pt={5} pb={5}>
          <Box {...sectionContainerStyle} >
            <Typography variant="h2" {...h1Style} textAlign="center">法人情報</Typography>
            <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={1} ></Box>

            <Grid container spacing={3} mt={1}>

            <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant='h3' {...h2Style}>設立</Typography>
                    <Typography>2023年1月12日</Typography>

                    <Typography variant='h3' mt={2} {...h2Style}>理事長</Typography>
                    <Typography>有馬　かをり</Typography>

                    <Typography variant='h3' mt={2} {...h2Style}>住所</Typography>
                    <Typography>神奈川県横浜市戸塚区戸塚町４１１７番地４</Typography>

                    <Typography variant='h3' mt={2} {...h2Style}>外部リンク</Typography>
                    <Stack flexDirection="row" alignItems="center"><Link href="https://www.npo-homepage.go.jp/npoportal/detail/105002165" target="_blank" rel="noopener noreferrer">NPO法人ポータルサイト（内閣府）<LaunchIcon /></Link></Stack>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
              <Box component="iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1626.1128821900784!2d139.5298006573945!3d35.39965413647803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185a8328971d75%3A0xd85ec59a26ce223f!2z44CSMjQ0LTAwMDMg56We5aWI5bed55yM5qiq5rWc5biC5oi45aGa5Yy65oi45aGa55S677yU77yR77yR77yX!5e0!3m2!1sja!2sjp!4v1716041682654!5m2!1sja!2sjp" width="100%" height="400px" style={{ border: 0 }} allowfullscreen="" />
              </Grid>

              

              
            </Grid>
               
          </Box>
        </Box>

        <Box id="recruit" component="section" bgcolor="#a8deffd1" pt={5} pb={5}>
          <Box {...sectionContainerStyle} >
            <Typography variant="h2" {...h1Style} textAlign="center">採用情報</Typography>
            <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={1} />
            
            <Accordion defaultExpanded sx={{mt:5}}>
              
              <AccordionSummary sx={{backgroundColor:"#222f6c",color:"white"}}
                expandIcon={<ArrowDropDownIcon sx={{color:"white"}}/>}
              >
                <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Typography variant='h3' {...h2Style} color="white">正規指導員 - 募集要項</Typography>
                <Chip label="戸塚学童保育" color="primary" />
                <Chip label="第二戸塚学童保育" color="secondary"/>
                </Stack>
                
              </AccordionSummary>
              <AccordionDetails>
              
                <Typography variant="h3" {...h2Style}>仕事内容</Typography>
                <Typography>
                  小学生の放課後の保育とそれにともなう事務作業<br/><br/>
                  従事すべき業務の変更の範囲なし<br/>
                  就業の場所の変更の範囲なし
                </Typography>

                <Typography variant="h3" {...h2Style} mt={2}>給与</Typography>
                <Typography>【正職員】 月給 256,600円 〜 364,800円</Typography>

                <Typography variant="h3" {...h2Style} mt={2}>給与の備考</Typography>
                <Typography>内訳<br/>
                ・基本給　195,500円～290,500円<br/>
                ・キャリアアップ手当　5,600円～16,800円<br/>
                ・賃金改善加算手当　11,000円～13,000円<br/>
                ・処遇改善手当　4,500円<br/>
                ・固定残業代　40,000円/30時間分（超過分は追加で支給）<br/>
                <br/>
                主任手当（理事長が毎年決定）<br/>
                キャリアアップ手当（年1回経験年数に応じ決定）<br/>
                出張手当　3,000円～/泊<br/>
                通勤手当<br/>
                昇給　0円～5,500円/月<br/>
                試用期間なし</Typography>

                <Typography variant="h3" {...h2Style} mt={2}>待遇</Typography>
                <Typography>・雇用保険、健康保険、労災保険、厚生年金<br/>
                ・横浜市勤労者福祉共済（ハマふれんど）</Typography>

                <Typography variant="h3" {...h2Style} mt={2}>教育体制・研修</Typography>
                <Typography>資格取得支援あり</Typography>

                <Typography variant="h3" {...h2Style} mt={2}>勤務時間</Typography>
                <Typography>
                11:00～20:00内で8時間勤務<br/>
                8:00～20:00内で8時間勤務（夏・冬・春休みなど）<br/>
                休憩60分
                </Typography>

                <Typography variant="h3" {...h2Style} mt={2}>休日</Typography>
                <Typography>
                日曜、祝日、他月4回<br/>
                年間休日114日
                </Typography>

                <Typography variant="h3" {...h2Style} mt={2}>長期休暇・特別休暇</Typography>
                <Typography>
                育児休暇、看護休暇、有給休暇、慶弔休暇、年末年始休暇、夏季休暇
                </Typography>

                <Typography variant="h3" {...h2Style} mt={2}>応募要件</Typography>
                <Typography>放課後児童支援員任用資格保有者または放課後児童支援員資格取得要件を満たす方</Typography>

              </AccordionDetails>
            </Accordion>

            <Grid container mt={2}>
              <Grid item xs={12} md={12}>
                <Card >
                  <CardActionArea href='https://forms.gle/qWdxgJuazvV5hPHn8' target='_blank'>
                    <CardContent >
                      <Stack direction="row">
                        <Typography variant="h2" {...h2Style} flex={1}>応募フォーム</Typography>
                        <LaunchIcon/>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardContent>
                    <Stack direction="row" gap={2}>
                      <Typography variant="h2" {...h2Style} >問い合わせ先</Typography>

                      <Typography >TEL：045-864-9507　担当：誉田（ほんだ）</Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              
            </Grid>
            
               
          </Box>
        </Box>

        <Box id="report" component="section" bgcolor="#ffffffcc" pt={5} pb={5}>
          <Box {...sectionContainerStyle}>
            <Typography variant="h2" {...h1Style} textAlign="center">事業報告</Typography>
            <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={1} ></Box>

            

            <Grid container spacing={3} mt={1}>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardActionArea href="/static/media/report_2022.pdf" target="_blank" rel="noopener noreferrer">
                    <CardContent>
                      <Stack {...h2Style} flexDirection="row" gap={2} alignItems="center">
                        <Chip label="2022年度" color="primary"/>
                        <Box flex={1}>貸借対照表</Box>
                        <LaunchIcon/>
                      </Stack>
                    </CardContent>
                    
                  </CardActionArea>
                </Card>
              </Grid>

              {/* <Grid item xs={12} md={4}>
                <Card>
                  <CardActionArea>
                    <CardContent>
                      <Stack {...h2Style} flexDirection="row" gap={2} alignItems="center"><Chip label="2023年度" color="primary"/>貸借対照表</Stack>
                    </CardContent>
                    <CardActions >
                      <Button variant='outlined'>準備中</Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid> */}
            </Grid>
            
          </Box>
        </Box>

        <Footer/>

   </Box>    
  );
}

export default Top;
