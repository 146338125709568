import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Top from './Pages/Top';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path={"/recruit"} element={<Top/>}/>
          <Route path={"/"} element={<Top/>}/>
    
    </Routes>
        
      </BrowserRouter>
  );
}

export default App;
