import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";


function Footer() {

    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const style_menu={
        //textDecoration:"none",
        fontSize:15,
        color:"#393939",
    }

   

    return (
        <Box component="footer" bgcolor="#EBEBEB" p={5}>
            <Stack className='section-content' flexDirection={{sx:"column",md:"row"}} margin="0 auto" justifyContent="space-between" gap={1}>
                
                <Stack gap={2}>
                    {/* <Link sx={style_menu} href={"/"+queryString}>
                        <img src={process.env.PUBLIC_URL+"/static/media/LP/Logo.png"} alt="logo" width={150}></img>
                    </Link> */}
                    
                </Stack>

                <Stack flex={1} alignItems={{xs:"start",md:"end"}}>
                    <Stack flex={1} flexDirection={{xs:"column",md:"row"}} gap={{xs:2, md:20}} justifyContent={{xs:"center",md:"end"}}>
                       
                        <Stack gap={1}>
                            {/* <Link sx={style_menu} href="../privacy/" target='_blank'>プライバシーポリシー</Link> */}
                        </Stack>
                    </Stack>
                    <Box mt={1}>© 2024 Arinco</Box>
                </Stack>

                
            </Stack>
            
        </Box>
    );
}

export default Footer;